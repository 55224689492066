<template>
  <v-container>
    <v-row>
      <v-col
        cols="6"
        sm="4"
        md="3"
        lg="2"
        v-for="item in dashitems"
        :key="item.title"
      >
        <v-card :to="item.route" class="dashcard two-line">
          <v-card-text>
            <v-icon x-large>{{ item.icon }}</v-icon>
            <span>{{ item.title }}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { canNavigate } from '@/router/helpers'
import { NAVIGATION } from './constants'
export default {
  name: 'Dashboard',
  props: {},
  data() {
    return {
      nextEvents: [],
      nextEventsLoaded: false,
      showEventFilterDialog: false,
    }
  },
  computed: {
    dashitems() {
      return NAVIGATION.filter(item => {
        return item.route ? canNavigate(this.$router.resolve(item.route).route) : true
      })
    },
  },
}

</script>
